import { forwardRef } from "react"
import { IconBase } from "@phosphor-icons/react"

const weights = new Map([
    ["fill", <path
        d="M209.863 24.76a23.114 23.114 0 0123.111 23.111V209.65a23.11 23.11 0 01-23.111 23.111H48.085a23.112 23.112 0 01-23.111-23.111V47.871a23.111 23.111 0 0123.11-23.11h161.779zm-5.778 179.111v-61.244a37.672 37.672 0 00-37.671-37.671c-9.823 0-21.263 6.009-26.809 15.022v-12.827h-32.24v96.72h32.24v-56.969c0-8.897 7.164-16.177 16.062-16.177a16.176 16.176 0 0116.178 16.177v56.969h32.24zM69.809 89.009a19.413 19.413 0 0019.413-19.413c0-10.747-8.666-19.53-19.413-19.53a19.529 19.529 0 00-19.529 19.53c0 10.746 8.782 19.413 19.53 19.413zm16.062 114.862v-96.72H53.863v96.72H85.87z"
    />]])


const CustomLinkedin = forwardRef((props, ref) => (
    <IconBase ref={ref} {...props} weights={weights} />
))

CustomLinkedin.displayName = "CustomLinkedin";

export default CustomLinkedin;