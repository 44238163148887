import React from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { Copy, EnvelopeSimple, TwitterLogo, X, HandWaving } from '@phosphor-icons/react';
import "../styles/style.css";
import CopyToClipboard from 'react-copy-to-clipboard';
import CustomLinkedin from '../custom-icons/CustomLinkedin';
import CustomUnsplash from '../custom-icons/CustomUnsplash';

const ContactModal = ({ newToastNotification }) => {
    const emailCopied = () => {
        const toast = {
            type: 'Notification',
            icon: 'CheckCircle',
            message: 'Copied to Clipboard!',
            duration: 4000,
            dismissable: true,
            style: {
                top: 0
            }
        }

        newToastNotification(toast);
    }

    const openLink = (url) => {
        window.open(url, '_blank');
    }

    return (
        <Dialog.Root>
            <Dialog.Trigger asChild>
                <button className="connect-action-mobile" aria-label="Send message by selecting option below">
                    <HandWaving size={16} weight="fill" color="hsla(50, 93%, 40%, 1)" /> {" "} Connect & Collaborate
                </button>
            </Dialog.Trigger>
            <Dialog.Portal>
                <Dialog.Overlay className='dialog-overlay' />
                <Dialog.Content className='dialog-content'>
                    <div className="dialog-header">
                        <Dialog.Title className='dialog-title'>
                            Connect with Raunaq
                        </Dialog.Title>
                        <Dialog.Description className='dialog-description'>
                            Choose your preferred path: Email or Social.<br />I'm here for creative collaborations!
                        </Dialog.Description>
                    </div>
                    <div className="dialog-line-separator" />
                    <div className="dialog-email-content">
                        <div className="dialog-content-subheading">Email (hello@raunaqpatel.com)</div>
                        <CopyToClipboard onCopy={emailCopied} text={'hello@raunaqpatel.com'}>
                            <div className="dialog-icon-container">
                                <div className="dialog-icon-text">Copy Email</div>
                                <Copy size={16} weight='fill' color='#C6A608' />
                            </div>
                        </CopyToClipboard>
                        <div className="dialog-icon-container" onClick={() => openLink('mailto:hello@raunaqpatel.com')}>
                            <div className="dialog-icon-text">Default client</div>
                            <EnvelopeSimple size={16} weight='fill' color='#C6A608' />
                        </div>
                    </div>
                    <div className="dialog-social-content" onClick={() => openLink('https://linkedin.com/in/raunaqpatel')}>
                        <div className="dialog-content-subheading">Social links</div>
                        <div className="dialog-icon-container">
                            <div className="dialog-icon-text">Linkedin</div>
                            <CustomLinkedin size={16} weight='fill' color='#C6A608' />
                        </div>
                        <div className="dialog-icon-container" onClick={() => openLink('https://twitter.com/raunaqpatel')}>
                            <div className="dialog-icon-text">Twitter / X</div>
                            <TwitterLogo size={16} weight='fill' color='#C6A608' />
                        </div>
                        <div className="dialog-icon-container" onClick={() => openLink('https://unsplash.com/@raunaqpatel')}>
                            <div className="dialog-icon-text">Unsplash</div>
                            <CustomUnsplash size={16} weight='fill' color='#C6A608' />
                        </div>
                    </div>
                    <Dialog.Close asChild>
                        <button className="dialog-close-button" aria-label="Close">
                            <X size={16} weight='regular' color='#f2eac4' />
                        </button>
                    </Dialog.Close>
                </Dialog.Content>
            </Dialog.Portal>
        </Dialog.Root >
    );
};

export default ContactModal;
