import React from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { Clipboard, PaperPlaneRight, CaretRight } from '@phosphor-icons/react';
import "../styles/style.css";
import CopyToClipboard from 'react-copy-to-clipboard';

const ContactDropdown = ({ newToastNotification }) => {
    const emailCopied = () => {
        const toast = {
            type: 'Notification',
            icon: 'CheckCircle',
            message: 'Copied to Clipboard!',
            duration: 4000,
            dismissable: true,
            style: {
                bottom: 0
            }
        }

        newToastNotification(toast);
    }
    return (
        <DropdownMenu.Root>
            <DropdownMenu.Trigger asChild>
                <button className="chat-action pointer-context" aria-label="Send message by selecting option below">
                    <PaperPlaneRight size={16} weight="fill" color="hsla(50, 93%, 40%, 1)" /> {" "} Send Message
                </button>
            </DropdownMenu.Trigger>

            <DropdownMenu.Portal>
                <DropdownMenu.Content className="DropdownMenuContent" sideOffset={5}>
                    <DropdownMenu.Label className="DropdownMenuLabel">hello@raunaqpatel.com</DropdownMenu.Label>
                    <CopyToClipboard onCopy={emailCopied} text={'hello@raunaqpatel.com'}>
                        <DropdownMenu.Item className="DropdownMenuItem">
                            <div className="DropdownMenuIcon"><Clipboard size={16} weight="fill" /></div>Copy to Clipboard
                        </DropdownMenu.Item>
                    </CopyToClipboard>

                    <DropdownMenu.Separator className="DropdownMenuSeparator" />

                    <DropdownMenu.Sub>
                        <DropdownMenu.SubTrigger className="DropdownMenuSubTrigger">
                            <div className="DropdownMenuIcon"><PaperPlaneRight size={16} weight="fill" /></div>
                            Or send via email client
                            <div className="DropdownMenuIcon"><CaretRight size={16} weight="bold" /></div>
                        </DropdownMenu.SubTrigger>
                        <DropdownMenu.Portal>
                            <DropdownMenu.SubContent
                                className="DropdownMenuSubContent"
                                sideOffset={2}
                                alignOffset={-5}
                            >
                                <DropdownMenu.Item className="DropdownMenuItem"
                                    onSelect={(e) => {
                                        e.preventDefault();
                                        window.open(
                                            'mailto:hello@raunaqpatel.com',
                                        );
                                    }}>
                                    Default Client
                                </DropdownMenu.Item>
                                <DropdownMenu.Separator className="DropdownMenu.Separator" />
                                <DropdownMenu.Item className="DropdownMenuItem"
                                    onSelect={(e) => {
                                        e.preventDefault();
                                        window.open(
                                            'https://mail.google.com/mail/u/0/?to=hello@raunaqpatel.com&fs=1&tf=cm',
                                            '_blank'
                                        );
                                    }}>
                                    Gmail (web)
                                </DropdownMenu.Item>
                                <DropdownMenu.Item className="DropdownMenuItem"
                                    onSelect={(e) => {
                                        e.preventDefault();
                                        window.open(
                                            'https://outlook.live.com/mail/0/deeplink/compose?mailtouri=mailto:hello@raunaqpatel.com',
                                            '_blank'
                                        );
                                    }}>
                                    Outlook (web)
                                </DropdownMenu.Item>
                                <DropdownMenu.Item className="DropdownMenuItem"
                                    onSelect={(e) => {
                                        e.preventDefault();
                                        window.open(
                                            'https://compose.mail.yahoo.com/?to=hello@raunaqpatel.com',
                                            '_blank'
                                        );
                                    }}>
                                    Yahoo (web)
                                </DropdownMenu.Item>
                            </DropdownMenu.SubContent>
                        </DropdownMenu.Portal>
                    </DropdownMenu.Sub>



                    <DropdownMenu.Arrow className="DropdownMenuArrow" />
                </DropdownMenu.Content>
            </DropdownMenu.Portal>
        </DropdownMenu.Root>
    );
};

export default ContactDropdown;
