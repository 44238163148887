import React from "react";
import "../styles/style.css";
import { motion } from "framer-motion";

export const ChatBubble = (props) => {
    return (
        <motion.div variants={props.variants} className="chat-bubble">
            {props.content}
        </motion.div>
    );
};