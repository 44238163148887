import React, { Fragment } from "react";
import "../styles/style.css";
import { ChatBubble } from "../components/ChatBubble";
import { ChatAction } from "../components/ChatAction";
import ContactDropdown from "../components/ContactDropdown";
import ToastNotification from "../components/ToastNotification";
import { easeInOut, motion } from "framer-motion";
import RPAnimated from "../components/RPAnimated";
import { useMediaQuery } from "react-responsive";
import ContactModal from "../components/ContactModal";


export const HomePage = () => {
    const [toastData, setToastData] = React.useState(null);

    const sendToastData = (data) => {
        setToastData(data);
    };
    // for media queries
    const isMobile = useMediaQuery({ query: '(max-width: 480px)' })


    // for framer motion
    const containerVariant = {
        hidden: { opacity: 0 },
        show: {
            opacity: 1,
            transition: {
                ease: easeInOut,
                staggerChildren: 0.12,
                delayChildren: 0.3
            }
        }
    }

    const itemVariant = {
        hidden: { opacity: 0, y: 15, scale: 0.95 },
        show: {
            scale: 1,
            opacity: 1,
            y: 0,
            transformOrigin: "top center",
            transition: {
                type: "spring",
                damping: 4,
                mass: 0.38,
                stiffness: 50
                // ease: [.54, .01, .93, .93]
            }
        }
    }

    return (
        <div className="main">
            <div className="profile-picture-div">
                <img src='assets/raunaq.webp' alt="Raunaq Patel" className="profile-picture" />
            </div>
            <div className="content-div">
                <div className="logo">
                    <RPAnimated />
                </div>
                <motion.div className="content" variants={containerVariant} initial="hidden" animate="show">
                    <ChatBubble variants={itemVariant} content={
                        <Fragment>
                            <div className="chat-image">
                                <img src="/assets/raunaq.webp" alt="Raunaq Patel" />
                            </div>
                        </Fragment>
                    } />
                    <ChatBubble variants={itemVariant} content={
                        <Fragment>
                            <div className="chat-intro">
                                <span className="emphasised-text">Hello</span>
                                <img className="pikachu-wave" alt="Pikachu wave" src="/assets/pikachu_wave.gif" height={'18px'} />
                                <span className="emphasised-text">,</span>
                            </div>
                        </Fragment>
                    } />
                    <ChatBubble variants={itemVariant} content={
                        <Fragment>
                            <span className="emphasised-text">I'm Raunaq, </span>
                            <span className="body-text">{" "}a designer currently based in Helsinki 🇫🇮</span>
                        </Fragment>
                    } />
                    <ChatBubble variants={itemVariant} content={
                        <Fragment>
                            <span className="emphasised-text">For over a decade,</span>
                            <span className="body-text">{" "}I've been passionately pushing pixels, striving to craft delightful experiences.</span>
                        </Fragment>
                    } />
                    <ChatBubble variants={itemVariant} content={
                        <Fragment>
                            <span className="emphasised-text">My journey has led me,</span>
                            <span className="body-text">{" "}to my current role as <br />the </span>
                            <span className="important-text">{" "}Head of Product Design</span>
                            <span className="body-text">{" "}at{" "}</span>
                            <span><a href="http://iprally.com" target="_blank" rel="noopener noreferrer">IPRally</a></span>
                            <span className="body-text">{" "}where I'm dedicated to establishing a strong design culture and shaping the future of design in the patent industry.  Prior to this,<br />I contributed to the success of </span>
                            <span><a href="http://brella.io" target="_blank" rel="noopener noreferrer">Brella</a></span>
                            <span className="body-text">{" "}and{" "}</span>
                            <span><a href="http://fractalink.com" target="_blank" rel="noopener noreferrer">Fractal Ink</a></span>
                            <span className="body-text">.</span>
                        </Fragment>
                    } />

                    <ChatBubble variants={itemVariant} content={
                        <Fragment>
                            <span className="emphasised-text">I've had the privilege of collaborating</span>
                            <span className="body-text">{" "}with talented minds, crafting designs for top names like {" "}</span>
                            <span><a href="https://www.bluestarindia.com/" target="_blank" rel="noopener noreferrer">Bluestar</a></span>
                            <span className="body-text">,{" "}</span>
                            <span><a href="https://www.mahindrafirstchoice.com/" target="_blank" rel="noopener noreferrer">Mahinda First Choice</a></span>
                            <span className="body-text">,{" "}</span>
                            <span><a href="https://www.reliancehomefinance.com/" target="_blank" rel="noopener noreferrer">Reliance Home Finance</a></span>
                            <span className="body-text">,{" "}</span>
                            <span><a href="https://x.company/" target="_blank" rel="noopener noreferrer">X (Alphabet)</a></span>
                            <span className="body-text">, &{" "}</span>
                            <span><a href="https://cookpad.com/uk/home" target="_blank" rel="noopener noreferrer">Cookpad</a></span>
                            <span className="body-text">.</span>
                        </Fragment>
                    } />
                    <ChatBubble variants={itemVariant} content={
                        <Fragment>
                            <span className="emphasised-text">But the best part?<br /></span>
                            <span className="body-text">I'm always eager for creative collaboration.<br />Let's make some design magic together!</span>
                        </Fragment>
                    } />

                    {isMobile ?
                        <div className="mobile-action">
                            <ContactModal newToastNotification={sendToastData} />
                        </div>
                        :
                        <motion.div variants={itemVariant} className="chat-actions-div">
                            <ChatAction icon={'CustomLinkedin'} text={'Connect via Linkedin'} action={'https://linkedin.com/in/raunaqpatel'} />
                            <ChatAction icon={'TwitterLogo'} text={'Connect via Twitter'} action={'https://twitter.com/raunaqpatel'} />
                            <ChatAction icon={'CustomUnsplash'} text={'Follow on Unsplash'} action={'https://unsplash.com/@raunaqpatel'} />
                            <ContactDropdown newToastNotification={sendToastData} />
                        </motion.div>
                    }

                </motion.div>
            </div>
            <ToastNotification toastData={toastData} onToastRemoval={() => setToastData(null)} />
        </div >
    );
}; 