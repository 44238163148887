import React from "react";
import "../styles/style.css";
import { PaperPlaneRight, TwitterLogo } from "@phosphor-icons/react";
import CustomLinkedin from "../custom-icons/CustomLinkedin";
import CustomUnsplash from "../custom-icons/CustomUnsplash";


export const ChatAction = (props) => {

    const openLink = (url) => {
        // Perform an action with the provided URL, e.g., open a new tab/window
        window.open(url, '_blank');
    };
    // Define a mapping of icon names to their corresponding component
    const iconComponents = {
        PaperPlaneRight: PaperPlaneRight,
        TwitterLogo: TwitterLogo,
        CustomLinkedin: CustomLinkedin,
        CustomUnsplash: CustomUnsplash
    };

    // Use React.createElement to render the appropriate icon component
    const Icon = iconComponents[props.icon] || null;
    return (
        <button onClick={() => { openLink(props.action) }} className="chat-action">
            {(Icon) ? <Icon size={16} weight="fill" color="hsla(50, 93%, 40%, 1)" /> : null}
            {" "}
            {props.text}
        </button>
    );
};