import React from 'react';
import '@lottiefiles/lottie-player';
import { create } from '@lottiefiles/lottie-interactivity';
import { motion } from 'framer-motion';


class RPAnimated extends React.Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef(); // 1. create a reference for the lottie player
    }
    componentDidMount() {
        // 3. listen for player load. see lottie player repo for other events
        this.myRef.current.addEventListener('load', function (e) {
            // 4. configure the interactivity library
            create({
                player: '#RPLogoLottie',
                mode: 'cursor',
                actions: [
                    {
                        type: "hold"
                    }
                ]
            });
        });
    }
    render() {
        return (
            <motion.div className="RPAnimated" whileHover={{ scale: 1.1, transition: { type: "spring", bounce: 0.5 } }}>
                <lottie-player
                    ref={this.myRef} // 2. set the reference for the player
                    id="RPLogoLottie"
                    mode="normal"
                    speed="3"
                    src="https://lottie.host/3c281c1d-c7c3-4a13-ae24-8c9bdd237d81/lA7CLBgM4d.json"
                    style={{ width: '25px', height: '32px' }}
                ></lottie-player>
            </motion.div>
        );
    }
}

export default RPAnimated;