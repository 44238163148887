import { forwardRef } from "react"
import { IconBase } from "@phosphor-icons/react"

const weights = new Map([
    ["fill", <path
        d="M65 59.26V.76h78v58.5H65zm78 32.5h65v117H0v-117h65v58.5h78v-58.5z"
    />]])


const CustomUnsplash = forwardRef((props, ref) => (
    <IconBase ref={ref} {...props} weights={weights} />
))

CustomUnsplash.displayName = "CustomUnsplash";

export default CustomUnsplash;