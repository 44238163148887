import * as React from 'react';
import * as Toast from '@radix-ui/react-toast';
import "../styles/style.css";
import { CheckCircle, X, Info } from '@phosphor-icons/react';

const ToastNotification = ({ toastData, onToastRemoval }) => {
    const [open, setOpen] = React.useState(false);
    const timerRef = React.useRef(0);

    const defaultToast = {
        type: 'Notification',
        icon: Info,
        message: 'Hello World',
        duration: 5000,
        dismissable: true,
        style: {
            bottom: 0
        }
    }
    const [toastObject, setToastObject] = React.useState(defaultToast);

    //For mapping icon via prop
    const iconComponents = {
        CheckCircle: CheckCircle
    };
    // Use React.createElement to render the appropriate icon component
    const Icon = iconComponents[toastObject.icon] || null;


    React.useEffect(() => {
        if (toastData) {
            setOpen(false);
            const toast = {
                type: toastData.type,
                icon: toastData.icon,
                message: toastData.message,
                duration: toastData.duration,
                dismissable: toastData.dismissable,
                style: toastData.style
            }
            setToastObject(toast)
            timerRef.current = window.setTimeout(() => {
                setOpen(true);
                onToastRemoval();
            }, 100);
        }
    }, [toastData, onToastRemoval]);


    React.useEffect(() => {
        return () => clearTimeout(timerRef.current);
    }, []);

    return (
        <Toast.Provider swipeDirection='right' duration={toastObject.duration}>
            <Toast.Root type='foreground' open={open} onOpenChange={setOpen} className='toast-root' >
                <Toast.Title className='toast-title'>
                    <div className="ToastIcon">
                        {(Icon) ? <Icon size={16} weight="fill" color="#9BBA1D" /> : null}
                    </div>
                    {toastObject.message}
                </Toast.Title>
                {toastObject.dismissable &&
                    <Toast.Close className='toast-close'>
                        <X size={16} weight='bold' color='#656565' />
                    </Toast.Close>
                }

            </Toast.Root>
            <Toast.Viewport className="toast-viewport" style={toastObject.style} />
        </Toast.Provider>
    );
};

export default ToastNotification;
